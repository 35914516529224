import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/layout';

import Head from 'components/head';

import Section from '~v2/section';
import Content from '~v2/blocks/content';
import Media from '~v2/blocks/media';
import Title from '~v2/elements/title';
import Copy from '~v2/elements/copy';
import Box from '~v2/elements/container/box';
import { colors } from 'constants/theme';

const Faq = ({
  data: {
    faqJson: { title, hero_section },
  },
}) => (
  <Layout isSmallHeader={true}>
    <Head
      pageTitle={title}
      pageDescription="The hemp industry is always changing, it can be hard to keep track of. You’ve got questions? We’ve got answers, check out our FAQ today."
    />
    <Section
      boundsProps={{
        fluid: true,
        valign: 'center',
        style: { width: '100%' },
        TABLET_DOWN: { margin: '2rem 0 0', direction: 'vertical' },
        TABLET_UP: { margin: '6rem 0 0' },
      }}
      containerProps={{
        bgColor: colors.white,
        fontColor: colors.trace_black,
      }}
      left={{
        children: (
          <Box>
            <Content
              header={{
                as: 'h1',
                text: hero_section.title,
                innerHtml: true,
              }}
            />
          </Box>
        ),
        boxProps: {
          fluid: true,
          TABLET_DOWN: { width: '100%' },
          TABLET_UP: { width: '60%' },
        },
      }}
      right={{
        children: <Media fluid={hero_section.image.childImageSharp.fluid} />,
        boxProps: {
          fluid: true,
          shiftDown: true,
          shiftLeft: true,
          TABLET_DOWN: { width: '100%', margin: '0 -10% -20% -15%' },
          TABLET_UP: { width: '40%' },
        },
      }}
    />
    <FaqRow
      bgColor={colors.nomad}
      title={
        <Title as="h3">
          What Is Trace?
          <br />
          What Problem Is It Solving?
          <br />
        </Title>
      }
      copy={
        <Copy>
          Trace is a Vermont-based blockchain-enabled soil-to-shelf tracking
          solution for the entire hemp industry. Trace’s mobile app is used to
          declare lots, submit & receive test results, transfer lots to
          extractors and distributors, and move product from soil to shelf in
          the state’s supply chain. Trace was specifically designed and tested
          by participants in the hemp supply chain to compliment rather than
          conflict with their normal business processes.
        </Copy>
      }
    />
    <FaqRow
      bgColor={colors.cool_grey}
      title={<Title as="h3">WHAT IS THE BLOCKCHAIN?</Title>}
      copy={
        <Copy>
          Blockchain is a distributed database with special properties including
          total transparency into how people interface with it, and increased
          security against many common types of cyber attacks. The blockchain
          allows tens of thousands of independent servers around the world
          collectively verify that rules governing the interaction with user
          data are being enforced at all times. Because of this, no single
          malicious actor can unilaterally alter the data or change the rules
          regulating a given blockchain-based good or service even if they
          wanted to. The blockchain provides a platform for web applications to
          deploy and operate securely, without the reliance on a single trusted
          third party to manage data and enforce the rules governing that data.
        </Copy>
      }
    />
    <FaqRow
      bgColor={colors.dusty_gold}
      title={
        <Title as="h3">
          WHAT IF I DON’T HAVE <br />
          INTERNET OR PHONE SERVICE?
        </Title>
      }
      copy={
        <Copy>
          You can still use the app to enter in all of your information which
          will be saved until you’re connected to the internet or have cell
          service.
        </Copy>
      }
    />
    <FaqRow
      bgColor={colors.nomad}
      title={
        <Title as="h3">
          WHAT IF I DON’T OWN A <br />
          COMPUTER? <br />
          CAN I STILL USE TRACE?
        </Title>
      }
      copy={
        <Copy>
          Yes! If you would like to declare your lots on Trace, but don’t own a
          smartphone or a computer, contact us at invoices@tracevt.com or
          802-495-8905
        </Copy>
      }
    />
    <FaqRow
      bgColor={colors.cool_grey}
      title={
        <Title as="h3">
          WHAT IF I’M AN ANDROID
          <br />
          USER? CAN I STILL USE TRACE?
        </Title>
      }
      copy={
        <Copy>
          Trace is currently designed for iOS, but you can sign up on our
          website and we will contact you before the Android release.
        </Copy>
      }
    />

    <FaqRow
      bgColor={colors.dusty_gold}
      title={<Title as="h3">WHY SHOULD I USE TRACE?</Title>}
      copy={
        <Copy>
          You can still use For cultivators, this is a way to prove and receive
          the maximum value for your crop by standardizing and verifying your
          crop/product information.
          <br />
          <br />
          No more need to take phone calls from random facebook brokers, no
          texting pictures or asking your lab for different versions of COAs.
          You list your lot information, we connect you with a verified lab,
          your results come standardized, and then you choose what crop
          information you want to list publicly, or share privately.
        </Copy>
      }
    />
  </Layout>
);

Faq.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Faq;

export const HeaderContainer = styled.div`
  width: auto;
  margin: 0 auto;
  padding-right: 2rem;

  ${MEDIA.MIN_LG_DESKTOP`
    width: 600px;
  `};

  h3 {
    margin-bottom: 0;
  }
`;

export const AnswerContainer = styled.div`
  min-height: 150px;
  display: flex;
  border-top: 1px dotted ${colors.warm_grey};
  
  padding: 1.8rem;
  
  p {
    align-self: center;
    color: ${colors.cool_grey}
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const FaqRow = ({ bgColor, title, copy }) => {
  let fontColor;

  switch (bgColor) {
    case colors.nomad:
      fontColor = colors.cool_grey;
      break;
    case colors.cool_grey:
      fontColor = colors.nomad;
      break;
    case colors.dusty_gold:
      fontColor = colors.cool_grey;
      break;
  }

  return (
    <Section
      boundsProps={{
        fluid: true,
        valign: 'center',
        style: { width: '100%' },
        TABLET_DOWN: { direction: 'vertical', padding: '2rem' },
        DESKTOP: { padding: '0 2rem' },
      }}
      containerProps={{
        bgColor: bgColor,
        fontColor: fontColor,
      }}
      left={{
        children: <HeaderContainer>{title}</HeaderContainer>,
        boxProps: {
          fluid: true,
          PHONE: { width: '100%', margin: '0 0 2rem' },
          DESKTOP_UP: { width: '50%' },
        },
      }}
      right={{
        children: <AnswerContainer>{copy}</AnswerContainer>,
        boxProps: {
          fluid: true,
          PHONE: { width: '100%' },
          DESKTOP_UP: { width: '50%' },
        },
        backgroundProps: {
          bgColor: colors.white,
        },
      }}
    />
  );
};

FaqRow.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
};

export const query = graphql`
  query faqQuery {
    faqJson {
      title
      hero_section {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
